// @flow
import { BASE_URL } from 'config';
import Router from 'next/router';

export const redirect = (to, ctx, permanent = false) => {
  const isExternal = to.startsWith('http://') || to.startsWith('https://');
  const location = isExternal ? to : `${BASE_URL}${to}`;
  const status = permanent ? 302 : 301;

  if (ctx.res) {
    ctx.res.writeHead(status, {
      Location: location,
    });
    ctx.res.end();
  } else {
    if (isExternal) {
      location.href = location;
    } else {
      Router.replace(location);
    }
  }
};

export const createRedirect = (to, permanent = false) => {
  const Redirect = () => null;

  Redirect.getInitialProps = ctx => redirect(to, ctx, permanent);

  return Redirect;
};
