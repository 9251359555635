// @flow
import React, { Component } from 'react';
import { PHONE_FORMATTED } from 'config';
import { CallTrackingContext } from 'context';

class CallTrackingProvider extends Component {
  state = null;

  componentDidMount() {
    if (window._googWcmGet) {
      window._googWcmGet((formatted, normal) => {
        this.setState({
          formatted,
          normal,
        });
      }, PHONE_FORMATTED);
    }
  }

  render() {
    const value = {
      state: this.state,
    };

    return <CallTrackingContext.Provider value={value} {...this.props} />;
  }
}

export default CallTrackingProvider;
