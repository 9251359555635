// @flow
import { redirect } from 'util/redirect';

import React, { Component } from 'react';
import { QuoteFlowContext } from 'context';
import { Purchase } from 'tulip-core/models';
import * as api from 'api';
import Router, { withRouter } from 'next/router';
import {
  PURCHASE_TYPE,
  EFUNERAL_PAYMENT_PLAN,
  DELIVERY_OPTION,
} from 'tulip-core';
import { KustomerManager } from 'tulip-core';
import type { PaymentMethod } from 'tulip-core/types';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';

import { KUSTOMER_HOOK_KEY } from '../config';
import EventManager from '../util/eventManager';
import initApollo from '../apollo-wrapper/init-apollo';
import updatePurchaseFieldValue from 'api/paperwork/updatePurchaseFieldValue';
import { QuestionType } from '../pages/Paperwork/types';
import createEFuneralSale from '/pages/Checkout/api/createEFuneralSale';
import SentryManager from '/util/SentryManager';
import _ from 'lodash/fp';
import createPurchaserContact from 'api/contact/createPurchaserContact';

type ActiveCase = {
  purchase: Purchase.VCustomer | null,
};
const client = initApollo();

const getCaseId = ctx => {
  if (ctx.pathname === '/case') {
    return ctx.query.id.toLowerCase();
  }
  return ctx.req.cookies.get('tulip_case_id');
};

const getGclid = query => {
  let gclid = null;
  if (query.gclid) {
    gclid = query.gclid;
  } else if (Cookies.get('tulip_gclid')) {
    gclid = Cookies.get('tulip_gclid');
    Cookies.remove('tulip_gclid');
  }
  return gclid;
};

export const fetchActiveCase = async ctx => {
  try {
    const caseId = getCaseId(ctx);
    if (caseId) {
      return api.assumeCase(caseId);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Prefetching active case failed', err);
  }
};

const getRedirectUrl = (router, activeCase) => {
  const purchase = activeCase && activeCase.purchase;

  const isQuoteModal = typeof router.query.quote !== 'undefined';
  const isQuotePage = router.pathname === '/quote';
  const isCheckoutPage = router.pathname.startsWith('/checkout/');
  const isSuccessPage = router.pathname === '/order-success';
  const isAffirmPurchasePage = router.pathname === '/checkout/affirm-purchase';
  const isSpecialPage =
    router.pathname.startsWith('/case/') ||
    router.pathname.startsWith('/invoice/') ||
    router.pathname.startsWith('/payment-method/');

  const hasActivePurchase = Boolean(purchase);
  const hasOpenPurchase = hasActivePurchase && !Purchase.isCommitted(purchase);
  const status = purchase && Purchase.determineStatus(purchase);
  const hasCommitedPurchase =
    hasActivePurchase &&
    (status === 'preneed-in-progress' || Purchase.isCommitted(purchase));

  // the Affirm Purchase page should always be reached - if the token is
  // invalid it'll redirect anyway.
  if (isAffirmPurchasePage) return;

  // prevent opening modal shouldn't on quote flow pages, weird stuff can happen
  if (
    isQuoteModal &&
    (isQuotePage || isCheckoutPage || isSuccessPage || isSpecialPage)
  )
    return '/?quote';

  // prevent entering quote flow without active purchase
  if (!hasActivePurchase && (isQuotePage || isCheckoutPage || isSuccessPage))
    return '/?quote';

  // prevent returning back to /?quote or reaching success page once user opened purchase
  if (hasOpenPurchase && (isQuoteModal || isSuccessPage)) return '/quote';

  // prevent reaching quote flow once user completed purchase
  if (hasCommitedPurchase && (isQuoteModal || isQuotePage || isCheckoutPage))
    return '/order-success';

  // prevent reaching success page without commited purchase
  if (!hasCommitedPurchase && isSuccessPage) return '/quote';

  // prevent reaching checkout page directly
  if (router.req && isCheckoutPage) return '/quote';
};

const cleanupFormValues = values => {
  delete values.stateArea;
  delete values.serviceTimelineImminent;

  const optionalValues = [
    'purchaserEmail',
    'purchaserFirstName',
    'purchaserLastName',
    'purchaserPhone',
  ];

  optionalValues.forEach(valueKey => {
    if (values[valueKey] === null || values[valueKey] === '') {
      delete values[valueKey];
    }
  });

  if (
    values.purchaseType === PURCHASE_TYPE.PRENEED ||
    values.purchaseType === PURCHASE_TYPE.PRENEED_INSURANCE
  ) {
    delete values.decedentImplant;
    delete values.decedentOverweight;
    delete values.decedentLocationType;
  }
  return values;
};

type Props = {
  activeCase: Object,
  router: Object,
  customer: Object,
};

class QuoteFlowProvider extends Component<Props> {
  state = {
    activeCase: null,
    sending: false,
    customer: null,
    preneed: null,
  };

  constructor(props) {
    super(props);
    this.state.activeCase = props.activeCase;
    // Initialize KustomerManager to handle updates to contact information
    KustomerManager.setKey(KUSTOMER_HOOK_KEY);
  }

  async componentDidMount() {
    const query = gql`
      query Customer {
        customer {
          did
          isStaff
        }
      }
    `;
    const response = await client.query({ query: query });
    let user = _.getOr(false, ['data', 'customer'])(response);
    this.setState({ customer: user });
    try {
      if (this.props.router.query.gclid) {
        Cookies.set('tulip_gclid', this.props.router.query.gclid, {
          expires: 30,
        });
      }
      if (this.state.activeCase) {
        const purchase = this.state.activeCase.purchase;
        return api.assumeCase(purchase.id);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Prefetching active case failed', err);
    }
  }

  static async getInitialProps(ctx) {
    if (ctx.req) {
      const activeCase = await fetchActiveCase(ctx);
      const redirectUrl = getRedirectUrl(ctx, activeCase);
      if (redirectUrl) {
        redirect(redirectUrl, ctx);
      }
      return { activeCase };
    }
    return {};
  }

  setActiveCase = async (activeCase: ActiveCase) => {
    if (!activeCase.purchase) {
      throw new Error('purchase is missing');
    }

    return new Promise(resolve => {
      this.setState({ activeCase }, resolve);
    });
  };

  clearActiveCase = async (redirect = true) => {
    await api.clearCase();
    if (redirect) await Router.push('/');

    this.setState({
      activeCase: null,
    });
  };

  continueQuote = (source: string) => {
    const {
      router: { pathname, query },
    } = this.props;
    // get quote
    if (!this.state.activeCase) {
      EventManager.registerEvent(EventManager.events.QUOTE_CLICKED, {
        category: 'Conversion Funnel',
      });
      return Router.push(`${pathname}?quote=true&source=${source}`);
    }

    // quote modal > quote page
    if (typeof query.quote !== 'undefined') {
      return Router.replace('/quote');
    }

    const { purchase } = this.state.activeCase;

    // view arrangement button
    if (Purchase.isCommitted(purchase)) {
      return Router.push('/order-success');
    }

    if (pathname === '/quote') {
      return Router.push(`/checkout/purchaser`);
    }

    if (pathname === `/checkout/purchaser`) {
      if (purchase.isPurchaserBeneficiary) {
        return Router.push(`/checkout/payment`);
      } else {
        return Router.push(`/checkout/decedent`);
      }
    }

    if (pathname === `/checkout/decedent`) {
      return Router.push(`/checkout/payment`);
    }

    if (pathname === `/checkout/payment`) {
      return Router.push(`/order-success`);
    }

    // unfinished arrangement

    // It's possilble for quote form to be seen in on partner pages
    // so moving to the quote page should take over the browser
    if (window.location !== window.top.location) {
      window.top.location = `${pathname}?quote`;
    } else {
      if (purchase.purchaseType === 'preneed_insurance') {
        return Router.push('/preneedquote');
      } else {
        return Router.push('/quote');
      }
    }
  };

  backQuote = () => {
    const {
      router: { pathname },
    } = this.props;
    const { purchase } = this.state.activeCase;

    if (pathname === '/checkout/purchaser') {
      return Router.push(`/quote`);
    }

    if (pathname === '/checkout/decedent') {
      return Router.push(`/checkout/purchaser`);
    }

    if (pathname === `/checkout/payment`) {
      if (purchase.isPurchaserBeneficiary) {
        return Router.push(`/checkout/purchaser`);
      } else {
        return Router.push(`/checkout/decedent`);
      }
    }
  };

  createQuote = async rawValues => {
    this.setState({ sending: true });
    this.setState({ preneed: rawValues });
    const {
      router: { query },
    } = this.props;

    // remove junk data if user went back in flow and chose different path
    const data = cleanupFormValues({ ...rawValues });

    if (data.purchaseType === PURCHASE_TYPE.PRENEED_INSURANCE) {
      data.deliveryOption = DELIVERY_OPTION.IN_PERSON_COLLECTION;
    }

    EventManager.registerEvent(EventManager.events.QUOTE_FLOW_COMPLETED, {
      category: 'Conversion Funnel',
      purchaseType: data.purchaseType,
    });

    // Temporary Hack to add google click id to purchase object
    // When we move purchase creation to hades, we should create new models tied to purchase
    // that registers events and visits which would store this type of data
    const googleClickId = getGclid(query);

    data['googleClickId'] = googleClickId;

    let json;

    try {
      json = await api.fromQuote(data);

      KustomerManager.updateContactInfo(json.purchase);
      createPurchaserContact(json.purchase.id);

      await this.setActiveCase(json);

      EventManager.registerEvent(EventManager.events.QUOTE_COMPLETED, {
        category: 'Conversion Funnel',
        case_id: this.state.activeCase.purchase.id,
        purchaseType: this.state.activeCase.purchase.purchaseType,
      });
      if (
        this.state.activeCase.purchase.purchaseType ===
        PURCHASE_TYPE.PRENEED_INSURANCE
      ) {
        await Promise.all([
          updatePurchaseFieldValue(
            {
              dob: {
                answer: data.dateOfBirth,
                qType: QuestionType.DATE,
              },
              qType: QuestionType.COMBINATION,
            },
            this.state.activeCase.purchase.id,
            'decedent',
            true
          ),
          updatePurchaseFieldValue(
            {
              answer: {
                label: rawValues.serviceTimelineImminent
                  ? 'imminent'
                  : 'future',
                value: !!rawValues.serviceTimelineImminent,
              },
              qType: QuestionType.CHECKBOX,
            },
            this.state.activeCase.purchase.id,
            'serviceTimelineImminent',
            true
          ),
        ]);
      }

      try {
        const salesperson = localStorage['salespersonId']
          ? localStorage['salespersonId']
          : '';
        const utmParams = sessionStorage['utmParams']
          ? JSON.parse(sessionStorage['utmParams'])
          : {};

        const apollo = initApollo();
        apollo.mutate({
          mutation: gql`
            mutation PushToSalesforce(
              $purchaseId: String!
              $salespersonId: String
              $utmParams: String
            ) {
              purchase(did: $purchaseId) {
                pushToSalesforce(
                  salespersonId: $salespersonId
                  utmParams: $utmParams
                ) {
                  ok
                }
              }
            }
          `,
          variables: {
            purchaseId: this.state.activeCase.purchase.id,
            salespersonId: salesperson,
            utmParams: JSON.stringify(utmParams),
          },
        });
      } catch (e) {
        if (typeof window !== undefined && window.Sentry) {
          window.Sentry.captureException(e);
        }
        try {
          window.Sentry.captureMessage(
            `ERROR PUSHING TO SF FOR ${this.state.activeCase.purchase.id}`
          );
        } catch (e) {
          // continue regardless of error
        }
      }

      await this.continueQuote();
    } catch (err) {
      alert(
        'There was an error continuing your order. If the error persists, please call us.'
      );
      throw new Error(err);
    }

    this.setState({ sending: false });
    return json;
  };

  updateQuote = async (values, proceed = true) => {
    this.setState({ sending: true });
    const { purchase } = this.state.activeCase;

    try {
      const json = await api.updatePurchase(purchase.id, {
        ...(purchase ? { purchaseType: purchase.purchaseType } : {}),
        ...values,
      });

      KustomerManager.updateContactInfo(json.purchase);

      await this.setActiveCase(json);
      if (proceed) await this.continueQuote();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      alert(
        'There was an error continuing your order. If the error persists, please call us.'
      );
    }

    this.setState({ sending: false });
  };

  checkoutPreNeedInsurance = async age => {
    this.setState({ sending: true });
    const { purchase } = this.state.activeCase;
    try {
      const paymentPlan = purchase.hasInstallments
        ? EFUNERAL_PAYMENT_PLAN.MULTI
        : EFUNERAL_PAYMENT_PLAN.SINGLE;
      let { redirectUrl } = await createEFuneralSale(
        purchase.id,
        age,
        paymentPlan
      );
      if (this.state.customer && this.state.customer.isStaff) {
        redirectUrl += `&representativeId=${this.state.customer.did}`;
      }
      window.location.href = redirectUrl;
    } catch (e) {
      SentryManager.captureException(e);
    }
    this.setState({ sending: false });
  };

  checkoutPayment = async (token, paymentMethod: PaymentMethod) => {
    this.setState({ sending: true });

    const { purchase } = this.state.activeCase;

    try {
      const json = await api.checkoutPayment({
        purchaseId: purchase.id,
        paymentMethod,
        token: token,
      });

      await this.setActiveCase(json);
      if (
        purchase.purchaseType === PURCHASE_TYPE.IMMEDIATELY ||
        purchase === PURCHASE_TYPE.PRENEED
      ) {
        EventManager.registerEvent(EventManager.events.PAYMENT_COMPLETED, {
          case_id: purchase.id,
          category: 'Conversion Funnel',
          value: Purchase.calculatePrices(purchase).total / 100,
          purchaseType: purchase.purchaseType,
        });
      } else {
        EventManager.registerEvent(EventManager.events.PREPAYMENT_COMPLETED, {
          case_id: purchase.id,
          category: 'Conversion Funnel',
          purchaseType: purchase.purchaseType,
        });
      }

      EventManager.registerEvent(EventManager.events.CHECKOUT_COMPLETED, {
        purchaseType: purchase.purchaseType,
        category: 'Conversion Funnel',
        case_id: purchase.id,
      });

      await this.continueQuote();
    } catch (err) {
      this.setState({ sending: false });
      throw new Error(err);
    }

    this.setState({ sending: false });
  };

  render() {
    const value = {
      state: this.state,
      actions: {
        clearActiveCase: this.clearActiveCase,
        setActiveCase: this.setActiveCase,
        continueQuote: this.continueQuote,
        updateQuote: this.updateQuote,
        createQuote: this.createQuote,
        checkoutPayment: this.checkoutPayment,
        checkoutPreNeedInsurance: this.checkoutPreNeedInsurance,
        backQuote: this.backQuote,
      },
    };

    return <QuoteFlowContext.Provider value={value} {...this.props} />;
  }
}

export default withRouter(QuoteFlowProvider);
