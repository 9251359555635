import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { QuoteFlowContext, QuoteFormContext } from 'context';
import withContext from 'hoc/withContext';
import { DELIVERY_OPTION } from 'tulip-core';

import EventManager from '../util/eventManager';
import { tulipApi } from '../api/client';

// this provider exists because of QuoteButton
class QuoteFormProvider extends Component {
  static propTypes = {
    purchase: PropTypes.object.isRequired,
    updateQuote: PropTypes.func.isRequired,
  };

  state = {
    values: {},
    validationShown: false,
  };

  initQuoteForm = () => {
    const { purchase } = this.props;

    this.updateQuoteForm({
      numDeathCertificates:
        purchase && purchase.numDeathCertificates
          ? purchase.numDeathCertificates
          : 0,
      deliveryOption:
        (purchase && purchase.deliveryOption) || DELIVERY_OPTION.MAIL,
      hasInstallments: (purchase && purchase.hasInstallments) || true,
      isGlobalCoverage: (purchase && purchase.isGlobalCoverage) || false,
      zip: (purchase && purchase.zip) || '',
      showDeliveryOptions: true,
      showAutoLocationInput: false,
      showValidationMessage: true,
      deliveryOptionsMessage: { message: '', className: '' },
      deliveryOptionValue: 0,
    });
  };

  validateQuoteForm = () => {
    this.setState({
      validationShown: true,
    });
  };

  updateQuoteForm = data => {
    this.setState(state => ({
      values: {
        ...state.values,
        ...data,
      },
    }));
  };

  sendQuoteForm = async () => {
    const { purchase, updateQuote } = this.props;
    const { values } = this.state;

    EventManager.registerEvent(EventManager.events.CHECKOUT_BEGIN, {
      case_id: purchase.id,
      purchaseType: purchase.purchaseType,
      category: 'Conversion Funnel',
    });

    EventManager.registerEvent(EventManager.events.QUOTE_ACCEPTED, {
      case_id: purchase.id,
      purchaseType: purchase.purchaseType,
      category: 'Conversion Funnel',
    });

    await updateQuote({
      numDeathCertificates: values.numDeathCertificates,
      deliveryOption: values.deliveryOption,
      zip: values.zip,
    });
    if (purchase.state === 'New York' || purchase.state === 'New Jersey') {
      const url = `/nynj-statement-goods/${purchase.id}`;
      tulipApi.post(url);
    }
  };

  render() {
    const value = {
      state: this.state,
      actions: {
        initQuoteForm: this.initQuoteForm,
        updateQuoteForm: this.updateQuoteForm,
        validateQuoteForm: this.validateQuoteForm,
        sendQuoteForm: this.sendQuoteForm,
      },
    };

    return <QuoteFormContext.Provider value={value} {...this.props} />;
  }
}

const quoteFlowConnector = withContext(
  QuoteFlowContext,
  ({ state, actions }) => ({
    purchase: state.activeCase && state.activeCase.purchase,
    updateQuote: actions.updateQuote,
  })
);

export default quoteFlowConnector(QuoteFormProvider);
