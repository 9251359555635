import gql from 'graphql-tag';
import _ from 'lodash/fp';
import initApollo from '/apollo-wrapper/init-apollo';

const query = gql`
  mutation createPurchaserContact($purchaseId: String!) {
    createPurchaserContact(purchaseId: $purchaseId) {
      ok
    }
  }
`;

const createPurchaserContact = async (purchaseId: string) => {
  const client = initApollo();

  const response = await client.mutate({
    mutation: query,
    variables: {
      purchaseId,
    },
  });

  return _.getOr(false, ['data', 'createPurchaserContact', 'ok'])(response);
};

export default createPurchaserContact;
