import React, { Component } from 'react';
import { StripeProvider as StripeElementsProvider } from 'react-stripe-elements';

import { STRIPE_PUBLISHABLE_KEY } from '../config';

class StripeProvider extends Component {
  state = {
    stripe: null,
  };

  componentDidMount() {
    if (window.Stripe) {
      this.initStripe();
    } else {
      const stripeEl = document.querySelector('#js-stripe');
      stripeEl
        ? stripeEl.addEventListener('load', () => {
            this.initStripe();
          })
        : // eslint-disable-next-line no-console
          console.error('Could not find #js-stripe');
    }
  }

  initStripe() {
    this.setState({
      stripe: window.Stripe(STRIPE_PUBLISHABLE_KEY),
    });
  }

  render() {
    return (
      <StripeElementsProvider stripe={this.state.stripe} {...this.props} />
    );
  }
}

export default StripeProvider;
