import gql from 'graphql-tag';
import _ from 'lodash/fp';
import initApollo from '/apollo-wrapper/init-apollo';

import { query as getPurchaseDetailFieldQuery } from './getPaperworkQuestionData';

/**
 * UpdatePurcaseFieldValue accepts a new value for the PurchaseDetailValue and
 * then either:
 *   a PurchaseDetailValueId or;
 *   a PurchaseDid and a PurchaseDetailFieldDid
 */
const authenticatedMutation = gql`
  mutation UpdatePurchaseFieldValue(
    $purchaseDid: String!
    $paperworkFieldId: String
    $updatedDetailValue: JSONString!
  ) {
    purchaseDetailValue(
      purchaseDid: $purchaseDid
      paperworkFieldId: $paperworkFieldId
      updatedDetailValue: $updatedDetailValue
    ) {
      ok
    }
  }
`;

const publicMutation = gql`
  mutation UpdatePurchaseFieldValue(
    $purchaseDid: String!
    $paperworkFieldId: String
    $updatedDetailValue: JSONString!
  ) {
    publicPurchaseDetailValue(
      purchaseDid: $purchaseDid
      paperworkFieldId: $paperworkFieldId
      updatedDetailValue: $updatedDetailValue
    ) {
      ok
    }
  }
`;
const updatePurchaseFieldValue = async (
  updatedDetailValue,
  purchaseDid,
  paperworkFieldId,
  isPublic = false
) => {
  const client = initApollo();
  const stringifiedValue = JSON.stringify(updatedDetailValue);
  let mutation = isPublic ? publicMutation : authenticatedMutation;
  const response = await client.mutate({
    mutation,
    variables: {
      purchaseDid,
      paperworkFieldId,
      updatedDetailValue: stringifiedValue,
    },
    refetchQueries: [
      {
        query: getPurchaseDetailFieldQuery,
        variables: {
          paperworkFieldIds: [paperworkFieldId],
          purchaseDid,
        },
      },
    ],
  });

  return _.getOr(null, ['data', 'purchaseDetailValue'])(response);
};

export default updatePurchaseFieldValue;
