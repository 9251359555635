import React, { useEffect, useState, useContext } from 'react';
import getUrnTypes from 'pages/Checkout/api/getUrnTypes';
import getTreeTypes from 'pages/Checkout/api/getTreeTypes';
import getKeepsakeTypes from 'pages/Checkout/api/getKeepsakeTypes';
import _ from 'lodash/fp';
import getFilteredMerchByPurchaseId from 'pages/Checkout/api/getFilteredMerchByPurchaseId';
import updatePurchasedMerch from 'pages/Checkout/api/updatePurchasedMerch';
import { QuoteFlowContext } from '/context';

export interface MerchType {
  did: string;
  name: string;
  displayName: string;
  description: string;
  price: number;
}

export interface PurchasedMerchContextProps {
  purchasedUrnDefinition: MerchType;
  purchasedTreeDefinition: MerchType;
  purchasedKeepsakeDefinition: MerchType;
  selectUrn: (urnDid: string) => void;
  selectTree: (treeDid: string) => void;
  selectKeepsake: (keepsakeDid: string) => void;
  isMoreThanOneUrnAvailable: boolean;
  refresh: () => void;
  urnTypeOptions: MerchType[];
  treeTypeOptions: MerchType[];
  keepsakeTypeOptions: MerchType[];
}

export const PurchasedMerchContext = React.createContext<
  Partial<PurchasedMerchContextProps>
>({
  isMoreThanOneUrnAvailable: false,
});

const PurchasedMerchProvider = (props: any) => {
  const [urnTypeOptions, setUrnTypeOptions] = useState<MerchType[]>([]);
  const [treeTypeOptions, setTreeTypeOptions] = useState<MerchType[]>([]);
  const [keepsakeTypeOptions, setKeepsakeTypeOptions] = useState<MerchType[]>(
    []
  );
  const [purchasedMerchDid, setPurchasedMerchDid] = useState('');
  const [purchasedTreeDid, setPurchasedTreeDid] = useState('');
  const [purchasedKeepsakeDid, setPurchasedKeepsakeDid] = useState('');
  const [purchasedUrnDefinition, setPurchasedMerchDefinition] = useState({});
  const [purchasedTreeDefinition, setPurchasedTreeDefinition] = useState({});
  const [
    purchasedKeepsakeDefinition,
    setPurchasedKeepsakeDefinition,
  ] = useState({});
  const [isMoreThanOneUrnAvailable, setIsMoreThanOneUrnAvailable] = useState(
    false
  );

  const { state: quoteFlowState } = useContext(QuoteFlowContext);
  const purchaseId = _.get(['activeCase', 'purchase', 'id'])(quoteFlowState);
  const countyDid = _.get(['activeCase', 'purchase', 'countyRef'])(
    quoteFlowState
  );

  const updateChosenMerch = async () => {
    const {
      did: newPurchasedMerchDid,
      merch: newPurchasedMerchDefinition,
    } = await getFilteredMerchByPurchaseId(purchaseId, [], ['urn']);

    setPurchasedMerchDid(newPurchasedMerchDid);
    setPurchasedMerchDefinition(newPurchasedMerchDefinition);
  };

  const updateChosenTree = async () => {
    const {
      did: newPurchasedMerchDid,
      merch: newPurchasedMerchDefinition,
    } = await getFilteredMerchByPurchaseId(purchaseId, [], ['tree']);

    setPurchasedTreeDid(newPurchasedMerchDid);
    setPurchasedTreeDefinition(newPurchasedMerchDefinition);
  };

  const updateChosenKeepsake = async () => {
    const {
      did: newPurchasedMerchDid,
      merch: newPurchasedMerchDefinition,
    } = await getFilteredMerchByPurchaseId(purchaseId, [], ['keepsake']);

    setPurchasedKeepsakeDid(newPurchasedMerchDid);
    setPurchasedKeepsakeDefinition(newPurchasedMerchDefinition);
  };

  useEffect(() => {
    if (purchaseId) {
      (async () => {
        const newUrnTypeOptions = await getUrnTypes(countyDid);
        const newTreeTypeOptions = await getTreeTypes(countyDid);
        const newKeepsakeTypeOptions = await getKeepsakeTypes(countyDid);
        setUrnTypeOptions(newUrnTypeOptions);
        setTreeTypeOptions(newTreeTypeOptions);
        setKeepsakeTypeOptions(newKeepsakeTypeOptions);
        setIsMoreThanOneUrnAvailable(newUrnTypeOptions.length > 1);
        updateChosenMerch();
        updateChosenTree();
        updateChosenKeepsake();
      })();
    }
  }, [purchaseId]);

  const value = {
    isMoreThanOneUrnAvailable,
    setIsMoreThanOneUrnAvailable,
    urnTypeOptions,
    treeTypeOptions,
    keepsakeTypeOptions,
    refresh: () => {
      updateChosenMerch();
      updateChosenTree();
      updateChosenKeepsake();
    },
    purchasedUrnDefinition,
    purchasedTreeDefinition,
    purchasedKeepsakeDefinition,
    selectUrn: async (urnDid: string) => {
      await updatePurchasedMerch(purchasedMerchDid, urnDid, 'urn');
      updateChosenMerch();
    },
    selectTree: async (treeDid: string) => {
      await updatePurchasedMerch(purchasedTreeDid, treeDid, 'tree');
      updateChosenTree();
    },
    selectKeepsake: async (keepsakeDid: string) => {
      await updatePurchasedMerch(purchasedKeepsakeDid, keepsakeDid, 'keepsake');
      updateChosenKeepsake();
    },
  };

  return <PurchasedMerchContext.Provider value={value} {...props} />;
};

export default PurchasedMerchProvider;
