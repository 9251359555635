import PropTypes from 'prop-types';
import React from 'react';
import { IPRegionContext } from 'context';

const IPRegionProvider = props => {
  return <IPRegionContext.Provider value={props.userIPRegion} {...props} />;
};

IPRegionProvider.propTypes = {
  userIPRegion: PropTypes.any,
};

export default IPRegionProvider;
