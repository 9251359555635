// @flow
import * as React from 'react';
import Head from 'next/head';
import { Footer, Header } from 'components';
import { QuoteModal } from 'pages/Checkout';
import { withRouter } from 'next/router';

type Props = {
  children: React.Node,
  hideHeaderItems: boolean,
  hideAllLayoutWrapping: boolean,
  router: {
    query: {
      quote?: string,
    },
    route: string,
  },
  phonePreNeed: string,
  phonePreNeedFormatted: string,
};

const LayoutProvider: React.ComponentType<Props> = (props: Props) => {
  if (props.hideAllLayoutWrapping) {
    return props.children;
  }

  return (
    <React.Fragment>
      <Head>
        <title>Simple, Affordable Direct Cremation | Tulip Cremation</title>
      </Head>

      <Header
        hideHeaderItems={props.hideHeaderItems}
        isSpanish={props && props.router && props.router.route === '/es'}
      />

      {props.children}

      <Footer
        phonePreNeed={props.phonePreNeed}
        phonePreNeedFormatted={props.phonePreNeedFormatted}
      />

      {props &&
        props.router &&
        typeof props.router.query.quote !== 'undefined' && <QuoteModal />}
    </React.Fragment>
  );
};

export default withRouter(LayoutProvider);
