import gql from 'graphql-tag';
import _ from 'lodash/fp';
import initApollo from '/apollo-wrapper/init-apollo';
import { MerchType } from '/providers/PurchasedMerchProvider';

const query = gql`
  mutation updatePurchasedMerch(
    $did: String!
    $merchId: String!
    $merchType: String!
  ) {
    updatePurchasedMerch(did: $did, merchId: $merchId, merchType: $merchType) {
      ok
    }
  }
`;

const updatePurchasedMerch = async (
  did,
  merchId,
  merchType
): Promise<MerchType> => {
  const client = initApollo();

  const response = await client.mutate({
    mutation: query,
    variables: {
      did,
      merchId,
      merchType,
    },
  });

  return _.getOr(null, ['data', 'updatePurchasedMerch'])(response);
};

export default updatePurchasedMerch;
