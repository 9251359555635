import gql from 'graphql-tag';
import _ from 'lodash/fp';
import initApollo from '/apollo-wrapper/init-apollo';
import { User } from '/types';

const query = gql`
  query GetSessionUser {
    customer {
      email
      firstName
      lastName
    }
  }
`;

export const getSessionUser = async (): Promise<User | null> => {
  const client = initApollo();

  const response = await client.query({
    query: query,
  });

  const user = _.getOr(undefined, ['data', 'customer'])(response);
  return user;
};
