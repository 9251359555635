import compose from 'util/compose';

import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';
import Router, { withRouter } from 'next/router';
import { QuoteForm } from 'components';

import { Modal } from '../../../components';

const NoDropShadowQuoteForm = styled(QuoteForm)`
  box-shadow: none;
  border-style: none;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuoteModal = ({ router, ...rest }) => {
  let referrer = '';
  if (router.query && router.query.referrer) {
    referrer = router.query.referrer;
  }
  return (
    <Modal
      onDismiss={() => {
        Router.replace(router.pathname);
      }}
      vBreakpoint={670}
      visible={true}
      maxWidth={480}
      {...rest}
    >
      <Wrapper>
        <NoDropShadowQuoteForm referrer={referrer} />
      </Wrapper>
    </Modal>
  );
};

QuoteModal.propTypes = {
  router: PropTypes.object.isRequired,
};

export default compose(withRouter)(QuoteModal);
