import gql from 'graphql-tag';
import _ from 'lodash/fp';
import initApollo from '/apollo-wrapper/init-apollo';
import { PurchaseDetails } from '/types';
import { MerchType } from '/providers/PurchasedMerchProvider';

interface FilteredMerch {
  merch: MerchType;
  did: string;
}

const query = gql`
  query getFilteredPurchasedMerchByPurchaseId(
    $purchaseId: String!
    $merchIds: [String]
    $merchTypes: [String]
  ) {
    purchasedMerchs(
      purchaseId: $purchaseId
      merchIds: $merchIds
      merchTypes: $merchTypes
    ) {
      did
      merch {
        name
        did
        price
        description
        displayName
      }
    }
  }
`;

const getFilteredMerchByPurchaseId = async (
  purchaseId: PurchaseDetails['id'],
  merchIds: string[] = [],
  merchTypes: string[] = []
): Promise<FilteredMerch> => {
  const client = initApollo();

  const response = await client.query({
    query: query,
    fetchPolicy: 'no-cache',
    variables: {
      purchaseId,
      merchIds,
      merchTypes,
    },
  });

  return _.getOr({}, ['data', 'purchasedMerchs', 0])(response);
};

export default getFilteredMerchByPurchaseId;
