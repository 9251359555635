// @flow
import React, { type Node } from 'react';

type Props = {
  children: Node[],
};

class ErrorBoundary extends React.Component<Props> {
  componentDidCatch(error, info) {
    if (!window.Sentry) {
      // eslint-disable-next-line no-console
      console.error('Caught error: ', error, info);
      return;
    }
    window.Sentry.captureException(error, info);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
