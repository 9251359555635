import _ from 'lodash/fp';
import { PurchaseDetails } from '/types';
import gql from 'graphql-tag';
import initApollo from '/apollo-wrapper/init-apollo';
import { flattenObject } from 'tulip-core';
import { MerchType } from '/providers/PurchasedMerchProvider';

const query = gql`
  query getUrnTypes($countyDid: String!) {
    geography {
      county(did: $countyDid) {
        region {
          merchSet(type: "urn") {
            edges {
              node {
                did
                name
                description
                displayName
                price
              }
            }
          }
        }
      }
    }
  }
`;

const getUrnTypes = async (
  countyDid: PurchaseDetails['countyRef']
): Promise<MerchType[]> => {
  const client = initApollo();

  const response = await client.query({
    query: query,
    variables: {
      countyDid,
    },
  });

  const region = _.getOr({}, ['data', 'geography', 'county', 'region'])(
    response
  );

  const merchSet = flattenObject(region).merchSet;

  return merchSet
    ? _.sortBy(merch => (merch.price ? merch.price : 0))(merchSet)
    : [];
};

export default getUrnTypes;
