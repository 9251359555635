import gql from 'graphql-tag';
import _ from 'lodash/fp';
import initApollo from '/apollo-wrapper/init-apollo';
import { PurchaseDetailField } from '/pages/Paperwork/types';

export const query = gql`
  query getPurchaseDetailField(
    $paperworkFieldIds: [String]!
    $purchaseDid: String!
  ) {
    purchaseDetailFields(paperworkFieldIds: $paperworkFieldIds) {
      paperworkFieldId
      questionText
      tooltipText
      tooltipTitle
      id
      name
      customerPurchaseDetailValue(purchaseDid: $purchaseDid) {
        value
      }
    }
  }
`;

const getPurchaseDetailField = async (
  paperworkFieldIds: string[],
  purchaseDid: string
): Promise<PurchaseDetailField[]> => {
  const client = initApollo();

  const response = await client.query({
    query: query,
    variables: {
      paperworkFieldIds,
      purchaseDid,
    },
  });

  return _.getOr([], ['data', 'purchaseDetailFields'])(response);
};

export default getPurchaseDetailField;
