import gql from 'graphql-tag';
import initApollo from '/apollo-wrapper/init-apollo';
import { EFUNERAL_PAYMENT_PLAN } from 'tulip-rest';
import { EFuneralSaleId, EFuneralRedirectUrl } from '/types';

type EFuneralPaymentPlan =
  | EFUNERAL_PAYMENT_PLAN.SINGLE
  | EFUNERAL_PAYMENT_PLAN.MULTI;

const mutation = gql`
  mutation CreateEFuneralSale(
    $purchaseDid: String!
    $age: Int!
    $paymentPlan: EFuneralPaymentPlan
  ) {
    createEfuneralSale(
      purchaseDid: $purchaseDid
      age: $age
      paymentPlan: $paymentPlan
    ) {
      ok
      efuneralRedirectUrl
      efuneralSaleId
    }
  }
`;

const createEFuneralSale = async (
  purchaseId: string,
  age: number,
  paymentPlan: EFuneralPaymentPlan
): Promise<{ redirectUrl: EFuneralRedirectUrl; saleId: EFuneralSaleId }> => {
  const client = initApollo();

  const response = await client.mutate({
    mutation,
    variables: {
      purchaseDid: purchaseId,
      age,
      paymentPlan,
    },
  });

  const {
    efuneralSaleId,
    efuneralRedirectUrl,
  } = response.data.createEfuneralSale;

  return { redirectUrl: efuneralRedirectUrl, saleId: efuneralSaleId };
};

export default createEFuneralSale;
