import { SimpleKV, CustError } from '/types';

export interface Questionnaire {
  sections: Section[];
  version: string;
}

export interface QuestionOption {
  label: string;
  value: string | number | boolean | SimpleKV;
  showIf?: Rule[][];
}

export interface PrePopulatedValueSetting {
  [key: string]: ComparedStaticValue | ComparedWithId;
}

export enum CustomValidator {
  IN_PAST = 'IN_PAST',
  IS_EMAIL = 'IS_EMAIL',
  IS_EMAIL_OR_NULL = 'IS_EMAIL_OR_NULL',
  IS_PHONE = 'IS_PHONE',
  IS_SSN = 'IS_SSN',
  IS_ADDRESS = 'IS_ADDRESS',
  IS_TIME = 'IS_TIME',
}

export interface PurchaseDetailField {
  paperworkFieldId: string;
  questionText: string;
  tooltipText: string;
  tooltipTitle: string;
  id: string;
  name: string;
  customerPurchaseDetailValue: PurchaseDetailValue | null;
}

export interface PurchaseDetailValue {
  value: string;
  id: string;
}

export interface QuestionValidation {
  customValidation?: CustomValidator;
  ruleGroup?: Rule[][];
  errorMessage?: string;
}

export interface Question {
  text?: string;
  tooltipText?: string;
  qType: QuestionType;
  options?: QuestionOption[];
  label?: string;
  id: string;
  placeholder?: string;
  tooltipTitle?: string;
  hiddenFields?: string[];
  customLabels?: {
    [labelId: string]: string;
  };
  prePopulateValues?: PrePopulatedValueSetting;
  validations?: QuestionValidation[];
  showIf?: Rule[][];
}

export type SubQuestionProps = Question & {
  error?: string;
};

export type QuestionProps = Question & {
  paperworkFieldId: string;
  values?: SimpleKV;
  touched?: SimpleKV;
  error?: CustError;
  valueChanged: (_newValue: any) => void;
  value: QuestionValue;
  prePopulatedValues?: SimpleKV;
  valuesSynced: boolean;
};

export interface PurchaseConditions {
  [id: string]: boolean;
}

export enum ComparedType {
  STATIC_VALUE,
  QUESTION_ID_VALUE,
  PAPERWORK_ID_VALUE,
  PURCHASE_CONDITION_ID_VALUE,
  PURCHASE_ATTR_VALUE,
}

export enum QuestionType {
  SINGLE_LINE_TEXT = 'SINGLE_LINE_TEXT',
  SIDE_BY_SIDE_TEXT = 'SIDE_BY_SIDE_TEXT',
  MULTI_LINE_TEXT = 'MULTI_LINE_TEXT',
  EMAIL = 'EMAIL',
  DATE = 'DATE',
  PHONE = 'PHONE',
  NUMBER = 'NUMBER',
  RADIO = 'RADIO',
  ADDRESS = 'ADDRESS',
  CHECKBOX = 'CHECKBOX',
  TIME = 'TIME',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  SIMPLE_PERSON = 'SIMPLE_PERSON',
  COMBINATION = 'COMBINATION',
  PASS = 'PASS',
  TEXT_AREA = 'TEXT_AREA',
}

export interface QuestionValue {
  qType: QuestionType;
  answer: QuestionAnswerValue;
}

export type QuestionAnswerValue = string | number | Date | SimpleKV | boolean;

export type CombinedAnswerValues = QuestionValue & {
  [id: string]: QuestionValue;
};

export interface ComparedStaticValue {
  type: ComparedType.STATIC_VALUE;
  value: string | number | Date | boolean;
}

// These types of Compared always have string values representing an Id/Key
export interface ComparedWithId {
  type:
    | ComparedType.PAPERWORK_ID_VALUE
    | ComparedType.QUESTION_ID_VALUE
    | ComparedType.PURCHASE_CONDITION_ID_VALUE
    | ComparedType.PURCHASE_ATTR_VALUE;
  value: string;
}

export type Comparator = (_a: any, _b: any) => boolean;

export type QuestionId = string;

export enum Comparison {
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  EQ = 'EQ',
  NE = 'NE',
  NOTSET = 'NOTSET',
  SET = 'SET',
}

export interface Rule {
  sub: ComparedStaticValue | ComparedWithId;
  obj?: ComparedStaticValue | ComparedWithId;
  comparison: Comparison;
}

export interface QuestionWrapper {
  id: QuestionId;
  question: Question;
  showIf: Rule[][]; // executed as an ANY within the list
  paperworkFieldId: string;
  subQuestions?: Question[];
  completedIf?: Rule[];
}

export interface Section {
  title: string;
  questions: QuestionWrapper[];
  content?: string[];
  options?: string[];
  specialType?: SpecialSectionType;
}

export enum SpecialSectionType {
  CONFIRM = 'confirm',
  REVIEW = 'review',
}

export enum SectionContent {
  NOK_SIGNERS = 'nokSigners',
  REVIEW = 'review',
}
