import React, { createContext, useEffect, useState } from 'react';
import { getSessionUser } from 'pages/Login/api/getSessionUser';
import { USER_POOL_ID, COGNITO_USER_CUSTOMER_CLIENT_ID } from '/config';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

interface ProviderFunctions {
  setUserEmail: (email: string | null) => void;
  setPassword: (Password: string | null) => void;
  setConfirmationText: (confirmationText: string) => void;
  setForgotEmail: (forgotEmail: string) => void;
  setHasCognitoRecord: (hasCognitoRecord: boolean) => void;
  setCognitoUser: (cognitoUser: CognitoUser) => void;
  setSessionUserAttributes: (attributes: {
    [id: string]: string | number | boolean;
  }) => void;
}

interface ProviderValues {
  userEmail: string;
  forgotEmail: string;
  password: string;
  userPool: CognitoUserPool;
  cognitoUser: CognitoUser;
  authenticatedUser: CognitoUser;
  hasCognitoRecord: boolean;
  confirmationText: string;
  sessionUserAttributes: { [id: string]: string | number | boolean };
  isGettingUser: boolean;
}

export interface ProviderProps
  extends Partial<ProviderValues>,
    ProviderFunctions {}

export interface LoadedProviderProps
  extends ProviderValues,
    ProviderFunctions {}

export const UserContext = createContext<Partial<ProviderProps>>({});

export const UserProvider = (props: any) => {
  const [userEmail, setUserEmail] = useState();
  const [forgotEmail, setForgotEmail] = useState();
  const [password, setPassword] = useState();
  const [userPool, setUserPool] = useState();
  const [cognitoUser, setCognitoUser] = useState();
  const [authenticatedUser, setAuthenticatedUser] = useState();
  const [hasCognitoRecord, setHasCognitoRecord] = useState(false);
  const [confirmationText, setConfirmationText] = useState();
  const [sessionUserAttributes, setSessionUserAttributes] = useState();
  const [isGettingUser, setIsGettingUser] = useState(true);
  useEffect(() => {
    (async () => {
      const user = await getSessionUser();
      if (user) {
        setUserEmail(user.email);
      }
    })();
    let _userPool = new CognitoUserPool({
      UserPoolId: USER_POOL_ID,
      ClientId: COGNITO_USER_CUSTOMER_CLIENT_ID,
    });
    setUserPool(_userPool);
    const getCognitoUser = async () => {
      let _cognitoUser = _userPool.getCurrentUser();
      if (_cognitoUser != null) {
        await _cognitoUser.getSession(function(err?: string) {
          if (err) {
            return;
          }
        });
        await setAuthenticatedUser(_cognitoUser);
        await setCognitoUser(_cognitoUser);
        await setUserEmail('');
      }
    };
    getCognitoUser();
    setIsGettingUser(false);
  }, []);

  const value: any = {
    userEmail,
    setUserEmail,
    cognitoUser,
    hasCognitoRecord,
    confirmationText,
    password,
    setPassword,
    setConfirmationText,
    isGettingUser,
    forgotEmail,
    authenticatedUser,
    setForgotEmail,
    setHasCognitoRecord,
    setCognitoUser,
    sessionUserAttributes,
    setSessionUserAttributes,
    userPool,
  };

  return <UserContext.Provider value={value} {...props} />;
};

export default UserProvider;
