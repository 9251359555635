// @flow
import React, { type Node } from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import App, { Container } from 'next/app';
import { Router } from 'next/router';
import Cookies from 'js-cookie';
import {
  ThemeProvider,
  StripeProvider,
  CallTrackingProvider,
  LayoutProvider,
  QuoteFormProvider,
  QuoteFlowProvider,
  IPRegionProvider,
  ModalProvider,
  UserProvider,
} from 'providers';
import { RELEASE_VERSION, ENVIRONMENT } from 'config';
import 'tulip-tulicons/style.scss';

import ErrorBoundary from '../src/components/ErrorBoundary';
import withApolloClient from '../src/apollo-wrapper/with-apollo-client';
import PurchasedMerchProvider from '../src/providers/PurchasedMerchProvider';

Router.events.on('routeChangeComplete', () => {
  window.scrollTo(0, 0);
});

class TulipCustomer extends App {
  static async getInitialProps({ Component, ctx }) {
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps({ ...ctx })
      : {};
    const quoteFlowProps = await QuoteFlowProvider.getInitialProps(ctx);

    const salespersonId = ctx.query.uid;

    const utmParams = {};

    Object.keys(ctx.query).forEach(function(key) {
      if (key.slice(0, 3) === 'utm') {
        utmParams[key] = ctx.query[key];
      }
    });

    const userIPRegion = ctx.req ? ctx.req.headers['fastly-region'] : null;

    return {
      pageProps,
      quoteFlowProps,
      salespersonId,
      utmParams,
      userIPRegion,
    };
  }

  // There's currently no way to Iterate over a module Object so here's a nice
  // hardcoded list of Providers
  providerList = [
    ThemeProvider,
    StripeProvider,
    CallTrackingProvider,
    ModalProvider,
  ];

  _addProviders = (starterNode: Node) => {
    return this.providerList.reduce((childNode, provider) => {
      return React.createElement(provider, null, childNode);
    }, starterNode);
  };

  componentDidMount() {
    if (this.props.salespersonId) {
      localStorage['salespersonId'] = this.props.salespersonId;
    }
    if (this.props.utmParams && Object.keys(this.props.utmParams).length) {
      sessionStorage['utmParams'] = JSON.stringify(this.props.utmParams);
    }
    if (!window.Sentry) {
      return;
    }
    window.Sentry.onLoad(() => {
      window.Sentry.init({
        release: RELEASE_VERSION,
        environment: ENVIRONMENT,
      });
      window.Sentry.configureScope(scope => {
        const caseId = Cookies.get('tulip_case_id');
        scope.setUser({ id: caseId });
      });
    });
  }

  render() {
    const {
      Component,
      pageProps,
      quoteFlowProps,
      apolloClient,
      userIPRegion,
    } = this.props;

    const { activeCase } = quoteFlowProps;
    return (
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          {this._addProviders(
            <ErrorBoundary>
              <Container>
                <IPRegionProvider {...{ userIPRegion }}>
                  <UserProvider>
                    <QuoteFlowProvider {...quoteFlowProps}>
                      <QuoteFormProvider>
                        <PurchasedMerchProvider
                          purchase={activeCase && activeCase.purchase}
                        >
                          <LayoutProvider
                            hideAllLayoutWrapping={
                              pageProps.hideAllLayoutWrapping
                            }
                            hideHeaderItems={pageProps.hideHeaderItems}
                            phonePreNeed={pageProps.phonePreNeed}
                            phonePreNeedFormatted={
                              pageProps.phonePreNeedFormatted
                            }
                          >
                            <Component {...pageProps} />
                          </LayoutProvider>
                        </PurchasedMerchProvider>
                      </QuoteFormProvider>
                    </QuoteFlowProvider>
                  </UserProvider>
                </IPRegionProvider>
              </Container>
            </ErrorBoundary>
          )}
        </ApolloHooksProvider>
      </ApolloProvider>
    );
  }
}

export default withApolloClient(TulipCustomer);
